import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./inicio/inicio.module').then( m => m.InicioPageModule)
  },   {
    path: 'news/:id',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module').then( m => m.CadastroPageModule)
  },
  {
    path: 'mantis-request',
    loadChildren: () => import('./mantis-request/mantis-request.module').then( m => m.MantisRequestPageModule)
  },
  {
    path: 'mantis-photo',
    loadChildren: () => import('./mantis-photo/mantis-photo.module').then( m => m.MantisPhotoPageModule)
  },
  {
    path: 'mantis-detalhe',
    loadChildren: () => import('./mantis-detalhe/mantis-detalhe.module').then( m => m.MantisDetalhePageModule)
  },
  {
    path: 'mantis-resumo',
    loadChildren: () => import('./mantis-resumo/mantis-resumo.module').then( m => m.MantisResumoPageModule)
  },
  {
    path: 'mantis-solicitados',
    loadChildren: () => import('./mantis-solicitados/mantis-solicitados.module').then( m => m.MantisSolicitadosPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'mantis/:id',
    loadChildren: () => import('./mantis/mantis.module').then( m => m.MantisPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'mantis-audio',
    loadChildren: () => import('./mantis-audio/mantis-audio.module').then( m => m.MantisAudioPageModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then( m => m.VideoPageModule)
  },
  {
    path: 'covid19',
    loadChildren: () => import('./covid19/covid19.module').then( m => m.Covid19PageModule)
  },
  {
    path: 'recuperar-senha',
    loadChildren: () => import('./recuperar-senha/recuperar-senha.module').then( m => m.RecuperarSenhaPageModule)
  },
  {
    path: 'mantis-instrucoes',
    loadChildren: () => import('./mantis-instrucoes/mantis-instrucoes.module').then( m => m.MantisInstrucoesPageModule)
  },
  {
    path: 'mantis-anexo',
    loadChildren: () => import('./mantis-anexo/mantis-anexo.module').then( m => m.MantisAnexoPageModule)
  },
  {
    path: 'politica',
    loadChildren: () => import('./politica/politica.module').then( m => m.PoliticaPageModule)
  },  {
    path: 'browser',
    loadChildren: () => import('./browser/browser.module').then( m => m.BrowserPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
