import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component'; 
import { AppRoutingModule } from './app-routing.module';
import { ComponentModules } from './components.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
 import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
 import { NgxMaskModule, IConfig } from 'ngx-mask';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [NgxMaskModule.forRoot(),BrowserModule, IonicModule.forRoot(), AppRoutingModule,ComponentModules,HttpClientModule,    IonicStorageModule.forRoot()
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy } ,Chooser,InAppBrowser ],
  bootstrap: [AppComponent],
  exports:[]
})
export class AppModule {}
