import { NgModule } from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { WordpressComponent } from "./wordpress/wordpress.component";
import { MenuComponent } from "./menu/menu.component";

@NgModule(
    {
        declarations:[WordpressComponent,MenuComponent],
        exports:[WordpressComponent,MenuComponent],
        imports:[IonicModule]
    }
)

export class ComponentModules{};