import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { GlobalConstants } from '../global-constants';

export interface Root2 {
  id: number
  date: string
  date_gmt: string
  guid: Guid
  modified: string
  modified_gmt: string
  slug: string
  status: string
  type: string
  link: string
  title: Title
  content: Content
  excerpt: Excerpt
  author: number
  featured_media: number
  comment_status: string
  ping_status: string
  sticky: boolean
  template: string
  format: string
  meta: any[]
  categories: number[]
  tags: number[]
  _links: Links
}

export interface Guid {
  rendered: string
}

export interface Title {
  rendered: string
}

export interface Content {
  rendered: string
  protected: boolean
}

export interface Excerpt {
  rendered: string
  protected: boolean
}

export interface Links {
  self: Self[]
  collection: Collection[]
  about: About[]
  author: Author[]
  replies: Reply[]
  "version-history": VersionHistory[]
  "predecessor-version": PredecessorVersion[]
  "wp:featuredmedia": Featuredmedum[]
  "wp:attachment": WpAttachment[]
  "wp:term": WpTerm[]
  curies: Cury[]
}

export interface Self {
  href: string
}

export interface Collection {
  href: string
}

export interface About {
  href: string
}

export interface Author {
  embeddable: boolean
  href: string
}

export interface Reply {
  embeddable: boolean
  href: string
}

export interface VersionHistory {
  count: number
  href: string
}

export interface PredecessorVersion {
  id: number
  href: string
}

export interface Featuredmedum {
  embeddable: boolean
  href: string
}

export interface WpAttachment {
  href: string
}

export interface WpTerm {
  taxonomy: string
  embeddable: boolean
  href: string
}

export interface Cury {
  name: string
  href: string
  templated: boolean
}


export class Noticia{
    imagem:string
    id:string
    titulo:string
}


@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  
 // endpoint = GlobalConstants.url+"services/news";
 //endpoint = "https://cvtr.rj.gov.br/?rest_route=/wp/v2/posts&_embed";
  endpoint = "https://inpas.prefeituradepetropolis.com.br/?rest_route=/wp/v2/posts&_embed";
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  public News:Root2 ;
  public dataReqNoticias:Date;

  constructor(private httpClient: HttpClient) { }

   
  getNews(): Observable<Root2[]> {
    return this.httpClient.get<Root2[]>(this.endpoint)
      .pipe(
        tap(users => console.log(users)) 
      );
  }


  getNew(id:string): Observable<Root2[]> {
    return this.httpClient.get<Root2[]>(this.endpoint+"/"+id)
      .pipe(
        tap(users => console.log(users)),
        catchError(this.handleError<Root2[]>('Get user', []))
      );
  }
 

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }  
  
}
